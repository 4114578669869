<template>
  <b-modal
    id="popup-interventions-types"
    ref="popup-interventions-types"
    centered="centered"
    :title="
      (interventionType.id ? 'Modifier' : 'Ajouter') + ` un type d'intervention`
    "
    @ok="create"
  >
    <div v-if="isCreatingInterventionType">
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formInterventionType">
      <b-row>
        <b-col cols="12" class="d-flex mb-1">
          <b-form-group
            label="Libellé *"
            label-for="Libellé"
            style="flex: 1"
            class="mr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="Libellé"
              rules="required"
            >
              <b-form-input
                id="label"
                v-model="interventionType.label"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Couleur *" label-for="Couleur">
            <validation-provider
              #default="{ errors }"
              name="Couleur"
              rules="required"
            >
              <b-form-input
                type="color"
                id="color"
                v-model="interventionType.color"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
                v-on:keyup.enter="create"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100 mx-0">
        <b-button
          class="float-left mr-1"
          v-if="archived"
          variant="outline-warning"
          @click="
            deleteInterventionTypeLocal(
              interventionType.id,
              interventionType.label
            )
          "
          ><feather-icon icon="Trash2Icon"/></b-button>
        <b-button
          class="float-left"
          v-if="archived"
          variant="outline-warning"
          @click="
            restoreInterventionTypeLocal(
              interventionType.id,
              interventionType.label
            )
          "
          >Restaurarer</b-button>
        <b-button
          class="float-left"
          v-else-if="interventionType.id"
          variant="outline-danger"
          @click="
            archiceInterventionTypeLocal(
              interventionType.id,
              interventionType.label
            )
          "
          ><feather-icon icon="ArchiveIcon"/>
        </b-button>
        <b-button class="float-right" variant="primary" @click="ok()">{{
          interventionType.id ? "Modifier" : "Ajouter"
        }}</b-button>
        <b-button
          class="float-right mr-1"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import "vue-swatches/dist/vue-swatches.css";
configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      archived: false,
      required,
      url,
      email,
      interventionType: {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      },
    };
  },
  methods: {
    popupCreateInterventionsTypesEvent(id) {
      if (id > 0) {
        this.fetchInterventionType(id).then((res) => {
          this.interventionType = res;
        });
      } else this.initializeForm();
    },

    popupCreateInterventionsTypesArchiveEvent(id, archive) {
      this.archived = archive;
      if (id > 0) {
        this.fetchInterventionType(id).then((res) => {
          this.interventionType = res;
        });
      } else this.initializeForm();
    },

    initializeForm() {
      this.interventionType = {
        label: "",
        workspaceId: this.$store.getters.workspaceSelected.id,
      };
    },
    restoreInterventionTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est irréversible.", {
          title: `Êtes-vous sûr de vouloir restaurer le type d'intervention ${label} ?`,
          size: "sm",
          okVariant: "warning",
          okTitle: "Restaurer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.restoreInterventionType(id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-interventions-types");
            });
          }
        });
    },
    deleteInterventionTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est irréversible.", {
          title: `Êtes-vous sûr de vouloir supprimer définitivement le type d'intervention ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteInterventionType(id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-interventions-types");
            });
          }
        });
    },
    archiveInterventionTypeLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est irréversible.", {
          title: `Êtes-vous sûr de vouloir archiver le type d'intervention ${label} ?`,
          size: "sm",
          okVariant: "danger",
          okTitle: "Archiver",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.archiveInterventionType(id);
            this.$nextTick(() => {
              this.$bvModal.hide("popup-interventions-types");
            });
          }
        });
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formInterventionType.validate().then((success1) => {
        if (success1) {
          if (this.interventionType.id)
            this.updateInterventionType(this.interventionType);
          else this.createInterventionType(this.interventionType);
          this.$nextTick(() => {
            this.$refs["popup-interventions-types"].toggle("#toggle-btn");
          });
        }
      });
    },
    ...mapActions([
      "createInterventionType",
      "updateInterventionType",
      "fetchInterventionType",
      "archiveInterventionType",
      "restoreInterventionType",
      "deleteInterventionType",
    ]),
  },
  computed: {
    ...mapGetters(["isCreatingInterventionType"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
};
</script>