<template>
  <div>
    <b-row class="m-0">
      <b-col class="content-header" cols="12">
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchIntervention"
            v-model="searchIntervention"
            placeholder="Rechercher un type d'intervention"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <b-button
            variant="primary"
            @click="popupCreateInterventionsTypes('')"
            class="d-flex px-1 mr-1"
          >
            <feather-icon icon="PlusIcon" />
            <span class="d-none d-md-block">Nouveau</span>
          </b-button>
          <b-dropdown
            class="bg-outline-primary btn-group-icon"
            variant="outline-primary"
          >
            <template #button-content>
              <span class="material-icons-outlined m-0 p-0">settings</span>
            </template>
            <b-dropdown-item
              class="w-full border-bottom-grey-light"
              @click="archive = !archive"
              >{{
                !archive
                  ? "Voir les type d'intervention archivés"
                  : "Voir les type d'intervention actifs"
              }}</b-dropdown-item
            >
            <b-dropdown-item class="w-full" @click="exportDataCSV"
              >Exporter en CSV</b-dropdown-item
            >
            <b-dropdown-item class="w-full" @click="exportDataXLS"
              >Exporter en XLS</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div
        class="text-center flex-center"
        v-if="
          isLoadingInterventionTypesList ||
          isLoadingInterventionTypesListArchived
        "
      >
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          {{
            archive
              ? "Chargement des archives en cours..."
              : "Chargement de la liste des collaborateurs..."
          }}
        </div>
      </div>
      <ag-grid-vue
        v-else
        style="width: 100%; height: 100%"
        class="ag-theme-material"
        :class="archive ? 'ag-archived' : ''"
        :rowData="gridData"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        @rowClicked="onRowClicked"
      >
      </ag-grid-vue>
    </div>
    <popup-interventions-types
      ref="popupCreateInterventionsTypes"
    ></popup-interventions-types>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import { agGridLocaleFR } from "@/data/agGridLocaleFR";

import {
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";

import PopupInterventionsTypes from "@/components/types/PopupInterventionsTypes";

export default {
  components: {
    BButton,
    AgGridVue,
    PopupInterventionsTypes,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      archive: false,
      filter: false,
      sidebar: false,
      activeSidebarIntervention: false,
      gridOptions: {
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 20,
      },
      gridApi: null,
      columnApi: null,
      searchIntervention: "",
    };
  },
  computed: {
    ...mapGetters([
      "interventionTypesList",
      "isLoadingInterventionTypesList",
      "isLoadingInterventionTypesListArchived",
      "isOpenSidebarInterventionType",
      "interventionTypesListArchived",
    ]),
    gridData() {
      if (this.archive) {
        return this.$store.getters.interventionTypesListArchived;
      } else {
        return this.$store.getters.interventionTypesList;
      }
    },
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      {
        field: "color",
        headerName: "Couleur",
        resizable: true,
        sortable: true,
        maxWidth: 120,
        cellRenderer: (params) => {
          return params.value
            ? `<div class="d-flex align-items-center"><div style=" margin-right: 5px; background-color: ${params.value}; width: 20px; height: 20px; border-radius: 100%;"></div><span> &nbsp;</span></div>`
            : "Aucune couleur sélectionnée";
        },
      },
      {
        field: "label",
        headerName: "Nom",
        resizable: true,
        sortable: true,
        minWidth: 160,
      },
    ];
  },
  created() {
    this.fetchInterventionTypesList();
    this.fetchCollaboratorsListArchived();
    this.fetchInterventionTypesListArchived();
  },
  methods: {
    ...mapActions([
      "fetchInterventionTypesList",
      "deleteInterventionType",
      "fetchCollaboratorsListArchived",
      "fetchInterventionTypesListArchived",
    ]),

    // Gestion d'AgGrid
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event) {
      if (this.archive) {
        this.popupCreateInterventionsTypesArchive(event.data.id, 1);
      } else {
        this.popupCreateInterventionsTypes(event.data.id);
      }
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchIntervention);
    },
    togglePanelColumn() {
      if (!this.gridOptions.sideBar || this.gridOptions.sideBar == null) {
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Colonnes",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: "columns",
        };
        this.filter = true;
      } else {
        this.gridOptions.sideBar = null;
        this.filter = false;
      }
    },
    exportDataXLS() {
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV() {
      this.gridApi.exportDataAsCsv();
    },
    popupCreateInterventionsTypes(id) {
      this.$bvModal.show("popup-interventions-types");
      this.$refs[
        "popupCreateInterventionsTypes"
      ].popupCreateInterventionsTypesEvent(id);
    },
    popupCreateInterventionsTypesArchive(id, archive) {
      this.$bvModal.show("popup-interventions-types");
      this.$refs[
        "popupCreateInterventionsTypes"
      ].popupCreateInterventionsTypesArchiveEvent(id, archive);
    },
  },
};
</script>
